import styled from "styled-components"
import { layout } from "styled-system"
import Grid from "./grid"
import DefaultText from "./text"
import Dots from "../images/dots.svg"

export const Container = styled(Grid.Item)`
  justify-self: center;
`
Container.defaultProps = {
  gridColumn: "main-start / main-end",
  mt: ["y.mobile.1.0", "y.desktop.1.0"],
  mb: ["y.mobile.3.0", "y.desktop.3.0"],
}

export const Button = styled.button`
  ${layout}

  background-color: #fff;
  background-image: url(${Dots});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3rem auto;
  border: 0.3rem solid #000;
  cursor: pointer;
  appearance: none;
`
Button.defaultProps = {
  width: "widths.8",
  height: ["heights.mobile.1.5", "heights.desktop.1.5"],
}

export const Text = styled(DefaultText)`
  user-select: none;
`
Text.defaultProps = {
  fontSize: ["mobile.2", "desktop.3"],
  lineHeight: ["mobile.2.0", "desktop.2.0"],
}
