import React from "react"
import { Container, Button, Text } from "./load-more.styles"

export default ({ isMore, onClick }) => {
  return (
    <Container>
      {isMore ? <Button onClick={onClick} /> : <Text>☺︎</Text>}
    </Container>
  )
}
