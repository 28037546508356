import React, { useState, useRef } from "react"
import chunk from "lodash.chunk"
import flatten from "lodash.flatten"

export const useLoadMore = (arr, limit = 1) => {
  const [cursor, setCursor] = useState(1)
  const computeIsMore = () => (cursor + 1) * limit < arr.length
  const [isMore, setIsMore] = useState(computeIsMore)

  const chunks = chunk(arr, limit)
  const items = flatten(Array.from(chunks).splice(0, cursor))

  const handleLoadMore = () => {
    setCursor(cursor + 1)
    setIsMore(computeIsMore)
  }

  return [isMore, handleLoadMore, items]
}

export default useLoadMore
