import React from "react"
import { Container } from "./portfolio-hero.styles"
import Grid from "../components/grid"
import Text from "../components/text"

export default ({ category }) => {
  return (
    <Container>
      <Grid.Item gridColumn="hero-start / hero-end" alignSelf="center">
        <Text.H1 variant="dark" textAlign="center">
          {category.length ? category[0].node.data.name : "Portfolio"}
        </Text.H1>
      </Grid.Item>
    </Container>
  )
}
