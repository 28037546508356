import styled from "styled-components"
import Grid from "./grid"

export const Container = styled(Grid)`
  width: 100%;
`
Container.defaultProps = {
  bg: "transparent",
  gridTemplateColumns: [
    "[full-start] 2.4rem [hero-start] repeat(4, 1fr) [hero-end] 2.4rem [full-end]",
    "[full-start] repeat(2, 1fr) [hero-start] repeat(4, 1fr) [hero-end] repeat(2, 1fr) [full-end]",
    "[full-start] repeat(4, 1fr) [hero-start] repeat(6, 1fr) [hero-end] repeat(4, 1fr) [full-end]",
  ],
  pb: ["y.mobile.2.0", "y.desktop.1.0"],
}
