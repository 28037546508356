import React, { useLayoutEffect, useMemo, useRef } from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import { useQueryParam, StringParam } from "use-query-params"
import { withPreview } from "gatsby-source-prismic"

import PageWrapper from "../components/page-wrapper"
import SEO from "../components/seo"
import Grid from "../components/grid"
import Hero from "../components/video-hero"
import PortfolioHero from "../components/portfolio-hero"
import SubNav from "../components/sub-nav"
import Projects from "../components/projects"
import LoadMore from "../components/load-more"
import Clients from "../components/clients"
import useLoadMore from "../utils/useLoadMore"

export const query = graphql`
  query Portfolio {
    site {
      siteMetadata {
        title
      }
    }

    allPrismicProject {
      edges {
        node {
          id
          uid
          prismicId
          data {
            name
            storyline {
              raw
            }
            category {
              document {
                ... on PrismicCategory {
                  uid
                  data {
                    name
                  }
                }
              }
            }
            cover_image {
              fluid(maxWidth: 1300) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }

    allPrismicClient {
      edges {
        node {
          id
          data {
            name
            image {
              fluid(maxWidth: 300) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }

    allPrismicCategory {
      edges {
        node {
          uid
          id
          data {
            name
          }
        }
      }
    }
  }
`

const PortfolioPage = (props) => {
  const [filter] = useQueryParam("filter", StringParam)
  const initialRender = useRef(true)

  useLayoutEffect(() => {
    if (!initialRender.current && filter) {
      setTimeout(
        () =>
          window.scrollTo({ top: window.innerHeight - 52, behavior: "smooth" }),
        200
      )
    }
    initialRender.current = false
  }, [filter])

  const [isMore, handleLoadMore, projects] = useLoadMore(
    get(props, `data.allPrismicProject.edges`, [])
      .map((item) => item.node)
      .filter((item) =>
        filter ? item.data.category?.document.uid === filter : true
      ),
    6
  )

  const categories = get(props, `data.allPrismicCategory.edges`, [])
  const category = useMemo(
    () => categories.filter((item) => item.node.uid === filter),
    [filter]
  )

  const clients = get(props, `data.allPrismicClient.edges`, [])

  return (
    <PageWrapper name="portfolio">
      <SEO title="Portfolio" />
      <Hero publicId="portfolio_tbpakn">
        <PortfolioHero category={category} />
      </Hero>
      <Grid>
        <SubNav data={categories} path="/portfolio" filter={filter} showAll />
        <Projects data={projects} />
        <LoadMore onClick={handleLoadMore} isMore={isMore} />
        <Clients data={clients} />
      </Grid>
    </PageWrapper>
  )
}

export default withPreview(PortfolioPage)
